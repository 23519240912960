import _ from 'lodash';
import {
  FETCH_TODAY_APPOINTMENTS_SUCCESS,
  FETCH_UPCOMING_APPOINTMENTS_SUCCESS,
  FETCH_COMPLETED_APPOINTMENTS_SUCCESS,
  FETCH_CANCELED_APPOINTMENTS_SUCCESS,
  FETCH_ALL_APPOINTMENTS_SUCCESS,
  FETCH_IN_PROGRESS_APPOINTMENTS_SUCCESS,
  FETCH_PREVIOUS_APPOINTMENTS_SUCCESS,
  FETCH_APPOINTMENT_SUCCESS,
  UPDATE_USER_APPOINTMENT,
  FETCH_USER_APPOINTMENT,
} from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_APPOINTMENT_SUCCESS:
      return { ...state, appointment: action.payload.data };
    case FETCH_TODAY_APPOINTMENTS_SUCCESS:
      return _.mapValues(action.payload.data);
    case FETCH_UPCOMING_APPOINTMENTS_SUCCESS:
      return _.mapValues(action.payload.data);
    case FETCH_COMPLETED_APPOINTMENTS_SUCCESS:
      return _.mapKeys(action.payload.data, 'id');
    case FETCH_CANCELED_APPOINTMENTS_SUCCESS:
      return _.mapKeys(action.payload.data, 'id');
    case FETCH_PREVIOUS_APPOINTMENTS_SUCCESS:
      return _.mapValues(action.payload.data);
    case FETCH_ALL_APPOINTMENTS_SUCCESS:
      return _.mapKeys(action.payload.data, 'id');
    case FETCH_IN_PROGRESS_APPOINTMENTS_SUCCESS:
      return _.mapKeys(action.payload.data, 'id');
    case UPDATE_USER_APPOINTMENT:
      return state;
    case FETCH_USER_APPOINTMENT:
      return { ...state, userAppointment: action.payload.data };
    default:
      return state;
  }
};
