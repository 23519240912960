import _ from 'lodash';

import {
  ADMIN_FETCH_DISCOUNT_CODES,
  ADMIN_FETCH_DISCOUNT_CODE,
  ADMIN_FETCH_USER_USED_DISCOUNT,
  ADMIN_FETCH_PAGINATED_DISCOUNT_CODES_SUCCESS,
  ADMIN_CREATE_DISCOUNT_CODE,
  ADMIN_CREATE_DISCOUNT_CODE_FAILURE,
  ADMIN_UPDATE_DISCOUNT_CODE,
  ADMIN_DELETE_DISCOUNT_CODE,
  ADMIN_SEARCH_DISCOUNT_CODES_SUCCESS,
} from '../../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case ADMIN_FETCH_USER_USED_DISCOUNT:
      return _.mapKeys(action.payload.data, 'id');
    case ADMIN_FETCH_DISCOUNT_CODES:
      return _.mapKeys(action.payload.data, 'id');
    case ADMIN_FETCH_PAGINATED_DISCOUNT_CODES_SUCCESS:
      return _.mapKeys(action.payload.data, 'id');
    case ADMIN_SEARCH_DISCOUNT_CODES_SUCCESS:
      return _.mapKeys(action.payload.data, 'id');
    case ADMIN_CREATE_DISCOUNT_CODE:
      return action.payload.data;
    case ADMIN_CREATE_DISCOUNT_CODE_FAILURE:
      return action.payload;
    case ADMIN_UPDATE_DISCOUNT_CODE:
      return action.payload.data;
    case ADMIN_FETCH_DISCOUNT_CODE:
      return action.payload.data;
    case ADMIN_DELETE_DISCOUNT_CODE:
      return _.omit(state, action.payload.data.id);
    default:
      return state;
  }
}
