import { AUTH_USER, AUTH_ERROR, UNAUTH_USER, TWO_FACTOR, TWO_FACTOR_ERROR, RESET_PASSWORD_ERROR } from '../actions/types';

const initialValues = {
  authenticated: false,
  two_factor: false,
  two_factor_number: {},
  log_id: null,
  error: null,
  email: null,
  resetPasswordError: '',
};

export default function (state = initialValues, action) {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state, authenticated: true, two_factor: false, error: null,
      };
    case UNAUTH_USER:
      return {
        ...state, authenticated: false, two_factor: false, error: null,
      };
    case AUTH_ERROR:
      return {
        ...state,
        authenticated: false,
        two_factor: false,
        error: action.payload,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordError: action.payload,
      };
    case TWO_FACTOR:
      return {
        ...state,
        email: action.payload.email,
        authenticated: false,
        log_id: action.payload.two_factor_id,
        two_factor: true,
        error: null,
      };
    case TWO_FACTOR_ERROR:
      return {
        ...state,
        authenticated: false,
        two_factor: true,
        error: action.payload,
      };
    default:
      return state;
  }
}
