import {
  FETCH_USER_LIFE_COACHING,
  CREATE_USER_LIFE_COACHING,
  DELETE_USER_LIFE_COACHING,
} from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_USER_LIFE_COACHING:
      return action.payload.data;
    case CREATE_USER_LIFE_COACHING:
      return action.payload.data;
    case DELETE_USER_LIFE_COACHING:
      return null;
    default:
      return state;
  }
}
