import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import { withRouter } from 'react-router-dom';

import { ENVIRONMENT } from '../environment';
import { cleanLocalStorage } from '../actions/auths';

// timeout: 2 hours (development server: 7 days)
const logoutMinutes = ENVIRONMENT !== 'DEVELOPMENT' ? 120 : 60 * 24 * 7;
const actionThrottle = 3000;

const authTokenName = 'token';
const lastTimestampName = 'last_action_at';
const logoutTimeout = 1000 * 60 * logoutMinutes;

class IdleManager extends Component {
  componentDidMount() {
    const token = localStorage.getItem(authTokenName);
    const lastTimestamp = localStorage.getItem(lastTimestampName);

    if (token && !this.isInClientSession()) {
      if (
        lastTimestamp &&
        Date.now() - lastTimestamp > logoutTimeout - 1000 * 60
      ) {
        this.signOut();
      } else {
        this.saveTimestamp();
      }
    }
  }

  isInClientSession = () =>
    window.location.pathname.startsWith('/sessions/clients');

  handleIdle = () => {
    const token = localStorage.getItem(authTokenName);
    const lastTimestamp = localStorage.getItem(lastTimestampName);
    const nowTimestamp = Date.now();

    if (token) {
      if (
        lastTimestamp &&
        nowTimestamp - lastTimestamp > logoutTimeout - 1000 * 60
      ) {
        this.signOut();
      }
    } else {
      this.props.history.push('/signin');
    }
  };

  handleActive = () => {
    const token = localStorage.getItem(authTokenName);

    if (!token) {
      this.props.history.push('/signin');
    }
  };

  handleAction = () => {
    this.saveTimestamp();
  };

  saveTimestamp = () => {
    const token = localStorage.getItem(authTokenName);

    if (token) {
      localStorage.setItem(lastTimestampName, Date.now());
    }
  };

  signOut = () => {
    cleanLocalStorage();
    this.props.history.push('/signin');
  };

  render() {
    if (this.isInClientSession()) {
      return <div></div>;
    }

    return (
      <IdleTimer
        element={document}
        onIdle={this.handleIdle}
        onAction={this.handleAction}
        onActive={this.handleActive}
        throttle={actionThrottle}
        timeout={logoutTimeout}
      />
    );
  }
}

IdleManager.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(IdleManager);
