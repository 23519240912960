const palette = {
  primary1Color: '#4E7BCD',
  accent1Color: '#29b6f6',
  alternateTextColor: '#ffffff',
  secondaryTextColor: '#9e9e9e',
  disabledColor: '#808080',
  textColor: '#242546',
};

const theme = {
  palette,
  slider: {
    selectionColor: '#00c853',
  },
  tableRow: {
    stripeColor: 'rgba(146, 180, 255, 0.1)',
    textColor: '#242546',
  },
  tableHeaderColumn: {
    textColor: '#3c75c7',
  },
  snackbar: {
    textColor: '#fff',
    backgroundColor: '#4E7BCD',
    actionColor: '#fff',
  },
  dialog: {
    bodyColor: '#212121',
    titleFontSize: 22,
    bodyFontSize: 14,
  },
  tabs: {
    backgroundColor: 'transparent',
    textColor: 'rgb(146, 180, 255)',
    selectedTextColor: '#212566',
  },
  checkbox: {
    checkedColor: '#adc6eb',
    boxColor: '#adc6eb',
    labelColor: '#3c75c7',
  },
  textField: {
    floatingLabelColor: '#3c75c7',
  },
  radioButton: {
    borderColor: '#adc6eb',
    checkedColor: '#3c75c7',
  },
};

export default theme;
