import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from 'react-intl';
import { signoutUser } from '../../actions/auths';

function LogOut(props) {
  const { open, handleClose } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage defaultMessage="Logout" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage defaultMessage="Are you sure you wish to log out?" />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button
          variant="contained"
          onClick={props.signoutUser}
          color="primary"
          style={{
            backgroundImage: 'linear-gradient(to top, #5fdb98, #3dd985)',
            outline: 'none',
          }}
          autoFocus
        >
          <FormattedMessage defaultMessage="Logout" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

LogOut.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  signoutUser: PropTypes.func.isRequired,
};

export default connect(null, { signoutUser })(LogOut);
