import _ from 'lodash';
import {
  ADMIN_FETCH_COMPANIES_SUCCESS,
  ADMIN_FETCH_COMPANY_SUCCESS,
  ADMIN_UPDATE_COMPANY_SUCCESS,
  ADMIN_CREATE_PEOPLE_COMPANY_SUCCESS,
  ADMIN_SEARCH_COMPANIES_SUCCESS,
  ADMIN_CREATE_COMPANY_SUCCESS,
  ADMIN_FETCH_COMPANY_LIST_SUCCESS,
} from '../../actions/types';

export default function(state = {}, action) {
  switch (action.type) {
    case ADMIN_FETCH_COMPANIES_SUCCESS:
    case ADMIN_SEARCH_COMPANIES_SUCCESS:
      return {
        meta: action.payload.data.meta,
        companies: _.mapKeys(action.payload.data.companies, 'id'),
      };
    case ADMIN_CREATE_COMPANY_SUCCESS:
    case ADMIN_CREATE_PEOPLE_COMPANY_SUCCESS:
    case ADMIN_UPDATE_COMPANY_SUCCESS:
    case ADMIN_FETCH_COMPANY_SUCCESS:
      return {
        meta: { ...state.meta },
        companies: {
          ...state.companies,
          [action.payload.data.id]: action.payload.data,
        },
      };
    case ADMIN_FETCH_COMPANY_LIST_SUCCESS:
      return { companies: action.payload.data };
    default:
      return state;
  }
}
