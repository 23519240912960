import _ from 'lodash';
import {
  FETCH_AVAILABILITY_SUCCESS,
  CREATE_AVAILABILITY,
  UPDATE_AVAILABILITY,
  CREATE_APPOINTMENT,
  UPDATE_APPOINTMENT,
  CANCEL_APPOINTMENT,
  DELETE_AVAILABILITY,
  COMBINE_AVAILABILITY,
} from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_AVAILABILITY_SUCCESS:
      return _.mapKeys(action.payload.data, 'id');
    case CREATE_AVAILABILITY:
      return { ...state, [action.payload.data.id]: action.payload.data };
    case UPDATE_AVAILABILITY:
      return { ...state, [action.payload.data.id]: action.payload.data };
    case CREATE_APPOINTMENT:
      return { ...state, [action.payload.data.id]: action.payload.data };
    case UPDATE_APPOINTMENT:
      return { ...state, [action.payload.data.id]: action.payload.data };
    case CANCEL_APPOINTMENT:
      return state;
    case DELETE_AVAILABILITY:
      return _.omit(state, [`${action.payload.data.id}`]);
    case COMBINE_AVAILABILITY:
      return state;
    default:
      return state;
  }
}
