import { FETCH_USER_SETTING, UPDATE_USER_SETTING } from '../actions/types';

const initialState = { current_release_notification_yn: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_SETTING:
      return action.payload.data || initialState;
    case UPDATE_USER_SETTING:
      return action.payload.data || initialState;
    default:
      return state;
  }
};
