const isClient = (roles = []) => {
  for (let i = 0; i < roles.length; i + 1) {
    if (roles[i].role_name === 'Client') {
      return true;
    }
    return false;
  }
};

export default isClient;
