import _ from 'lodash';
import {
  ADMIN_FETCH_PROVIDERS,
  ADMIN_UPDATE_CLIENT,
  ADMIN_UPDATE_PROVIDER,
  ADMIN_FETCH_PAGINATED_PROVIDERS_SUCCESS,
  ADMIN_FETCH_PROVIDER_SUCCESS,
  ADMIN_SEARCH_PROVIDERS_SUCCESS,
  ADMIN_FETCH_PROVIDER_CLIENTS_SUCCESS,
  ADMIN_FETCH_PAGINATED_CLIENTS_SUCCESS,
  ADMIN_SEARCH_CLIENTS_SUCCESS,
  ADMIN_FETCH_CLIENT_SUCCESS,
  ADMIN_FETCH_CLIENT_MATCH_SUCCESS,
  ADMIN_DELETE_GOVERNING_BODIES_SUCCESS,
  ADMIN_UPDATE_GOVERNING_BODIES_SUCCESS,
  ADMIN_FETCH_CLIENT_INSURANCE_SUCCESS,
  ADMIN_FETCH_CLIENT_INSURANCE_NOT_FOUND,
  ADMIN_PUT_CLIENT_INSURANCE_SUCCESS,
  ADMIN_DELETE_CLIENT_INSURANCE_SUCCESS,
} from '../../actions/types';

export default function(state = { pagination_info: {} }, action) {
  switch (action.type) {
    case ADMIN_FETCH_PROVIDERS:
      return { ...state, counsellors: _.mapKeys(action.payload.data, 'id') };
    case ADMIN_FETCH_PAGINATED_PROVIDERS_SUCCESS:
      return {
        ...state,
        counsellors: action.payload.data.providers,
        pagination_info: action.payload.data.pagination_info,
      };
    case ADMIN_FETCH_PROVIDER_SUCCESS:
      return { ...state, provider: action.payload.data };
    case ADMIN_FETCH_PAGINATED_CLIENTS_SUCCESS:
      return { ...state, clients: _.mapKeys(action.payload.data, 'id') };
    case ADMIN_FETCH_CLIENT_SUCCESS:
      return { ...state, client: action.payload.data };
    case ADMIN_FETCH_CLIENT_MATCH_SUCCESS:
      return { ...state, clientMatch: action.payload.data };
    case ADMIN_FETCH_PROVIDER_CLIENTS_SUCCESS:
      return {
        ...state,
        meta: { ...state.meta },
        providerClients: _.mapKeys(action.payload.data, 'id'),
      };
    case ADMIN_UPDATE_CLIENT:
      return { ...state, client: action.payload.data };
    case ADMIN_UPDATE_PROVIDER:
      return { ...state, provider: action.payload.data };
    case ADMIN_SEARCH_CLIENTS_SUCCESS:
      return { ...state, clients: _.mapKeys(action.payload.data, 'id') };
    case ADMIN_FETCH_CLIENT_INSURANCE_SUCCESS:
      return {
        ...state,
        clientInsuranceCoverage: _.mapKeys(action.payload.data, (value, key) =>
          _.camelCase(key),
        ),
      };
    case ADMIN_PUT_CLIENT_INSURANCE_SUCCESS:
      return {
        ...state,
        clientInsuranceCoverage: _.mapKeys(action.payload.data, (value, key) =>
          _.camelCase(key),
        ),
      };
    case ADMIN_FETCH_CLIENT_INSURANCE_NOT_FOUND:
    case ADMIN_DELETE_CLIENT_INSURANCE_SUCCESS:
      return {
        ...state,
        clientInsuranceCoverage: null,
      };
    case ADMIN_SEARCH_PROVIDERS_SUCCESS:
      return {
        ...state,
        counsellors: action.payload.data.providers,
        pagination_info: action.payload.data.pagination_info,
      };
    case ADMIN_DELETE_GOVERNING_BODIES_SUCCESS:
      return { ...state, provider: action.payload.data };
    case ADMIN_UPDATE_GOVERNING_BODIES_SUCCESS:
      return { ...state, provider: action.payload.data };

    default:
      return state;
  }
}
