import {
  FETCH_SESSION_STATUS_APPOINTMENT_REQUEST,
  FETCH_SESSION_STATUS_APPOINTMENT_SUCCESS,
  FETCH_SESSION_STATUS_APPOINTMENT_FAILURE,
  CREATE_SESSION_STATUS_CANCELLATION_REQUEST,
  CREATE_SESSION_STATUS_CANCELLATION_SUCCESS,
  CREATE_SESSION_STATUS_CANCELLATION_FAILURE,
  UPDATE_SESSION_STATUS_COMPLETE_REQUEST,
  UPDATE_SESSION_STATUS_COMPLETE_SUCCESS,
  UPDATE_SESSION_STATUS_COMPLETE_FAILURE,
  FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_REQUEST,
  FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_SUCCESS,
  FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_FAILURE,
  UPDATE_SESSION_STATUS_ADMIN_REQUEST,
  UPDATE_SESSION_STATUS_ADMIN_SUCCESS,
  UPDATE_SESSION_STATUS_ADMIN_FAILURE,
  CREATE_SESSION_STATUS_ADMIN_CANCELLATION_REQUEST,
  CREATE_SESSION_STATUS_ADMIN_CANCELLATION_SUCCESS,
  CREATE_SESSION_STATUS_ADMIN_CANCELLATION_FAILURE,
} from '../actions/types';

const initialState = {
  appointment: null,
  appointment_loading: false,
  appointment_error: null,
  cancellation_error: null,
  update_loading: false,
  update_error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SESSION_STATUS_APPOINTMENT_REQUEST:
    case FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_REQUEST:
      return {
        ...state,
        appointment: null,
        appointment_loading: true,
        appointment_error: null,
      };
    case FETCH_SESSION_STATUS_APPOINTMENT_SUCCESS:
    case FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointment: action.payload,
        appointment_loading: false,
      };
    case FETCH_SESSION_STATUS_APPOINTMENT_FAILURE:
    case FETCH_SESSION_STATUS_ADMIN_APPOINTMENT_FAILURE:
      return {
        ...state,
        appointment: null,
        appointment_loading: false,
        appointment_error: action.payload,
      };
    case CREATE_SESSION_STATUS_CANCELLATION_REQUEST:
    case CREATE_SESSION_STATUS_ADMIN_CANCELLATION_REQUEST:
      return {
        ...state,
        update_loading: true,
        cancellation_error: null,
      };
    case CREATE_SESSION_STATUS_CANCELLATION_SUCCESS:
    case CREATE_SESSION_STATUS_ADMIN_CANCELLATION_SUCCESS:
      return {
        ...state,
        update_loading: false,
      };
    case CREATE_SESSION_STATUS_CANCELLATION_FAILURE:
    case CREATE_SESSION_STATUS_ADMIN_CANCELLATION_FAILURE:
      return {
        ...state,
        update_loading: false,
        cancellation_error: action.payload,
      };
    case UPDATE_SESSION_STATUS_COMPLETE_REQUEST:
    case UPDATE_SESSION_STATUS_ADMIN_REQUEST:
      return {
        ...state,
        update_loading: true,
        update_error: null,
      };
    case UPDATE_SESSION_STATUS_COMPLETE_SUCCESS:
    case UPDATE_SESSION_STATUS_ADMIN_SUCCESS:
      return {
        ...state,
        update_loading: false,
      };
    case UPDATE_SESSION_STATUS_COMPLETE_FAILURE:
    case UPDATE_SESSION_STATUS_ADMIN_FAILURE:
      return {
        ...state,
        update_loading: false,
        update_error: action.payload,
      };
    default:
      return state;
  }
};
