import {
  UPDATE_USER_SUCCESS,
  CREATE_PROVIDER_SUCCESS,
  FETCH_USER_SUCCESS,
} from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER_SUCCESS:
    case UPDATE_USER_SUCCESS:
      return action.payload.data;
    case 'UPDATE_SUBSCRIPTION_SUCCESS':
      return { ...state, subscription: action.payload.data };
    case 'DELETE_SUBSCRIPTION_SUCCESS':
      return { ...state, subscription: action.payload.data };
    case CREATE_PROVIDER_SUCCESS:
      return { id: action.payload.data.user_id, ...action.payload.data };
    default:
      return state;
  }
};
