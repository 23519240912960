import _ from 'lodash';
import { FETCH_CHATS_SUCCESS, FETCH_CHAT_SUCCESS, UPDATE_CHAT_SUCCESS, FETCH_MESSAGES } from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_CHATS_SUCCESS:
      return {
        pagination_info: action.payload.data.meta,
        data: _.mapKeys(action.payload.data.chats, 'id'),
      };
    case FETCH_CHAT_SUCCESS:
      return { ...state, [action.payload.data.id]: action.payload.data };
    case UPDATE_CHAT_SUCCESS:
      return { ...state, [action.payload.data.id]: action.payload.data };
    default:
      return state;
  }
}
