import _ from 'lodash';
import {
  CREATE_CLIENT,
  FETCH_CLIENT_SUCCESS,
  UPDATE_CLIENT,
  FETCH_CLIENTS_SUCCESS,
} from '../actions/types';

// for an array of clients
const clientsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CLIENTS_SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
};

// for one client
const clientReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CLIENT_SUCCESS:
      return action.payload.data;
    case UPDATE_CLIENT:
      return action.payload.data;
    case CREATE_CLIENT:
      return action.payload.data;
    default:
      return state;
  }
};

const clients = { client: clientReducer, clients: clientsReducer };

export default clients;
