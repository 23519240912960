import {
  FETCH_DOCUMENT_PAGINATION_INFO,
  FETCH_APPOINTMENT_PAGINATION_INFO,
} from '../actions/types';

export default function (state = 1, action) {
  switch (action.type) {
    case FETCH_DOCUMENT_PAGINATION_INFO:
      return { ...action.payload.data[0] };
    case FETCH_APPOINTMENT_PAGINATION_INFO:
      return { ...action.payload.data[0] };
    default:
      return state;
  }
}
