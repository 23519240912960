import {
  FETCH_CLIENT_MATCH_SUCCESS,
  UPDATE_CLIENT_MATCH,
  CREATE_CLIENT_MATCH,
} from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_CLIENT_MATCH_SUCCESS:
      return action.payload.data;
    case UPDATE_CLIENT_MATCH:
      return action.payload.data;
    case CREATE_CLIENT_MATCH:
      return action.payload.data;
    default:
      return state;
  }
};
