import {
  FETCH_STRIPE_CUSTOMERS_REQUEST,
  FETCH_STRIPE_CUSTOMERS_SUCCESS,
  FETCH_STRIPE_CUSTOMERS_FAILURE,
  CREATE_STRIPE_CUSTOMERS_REQUEST,
  CREATE_STRIPE_CUSTOMERS_SUCCESS,
  CREATE_STRIPE_CUSTOMERS_FAILURE,
} from '../actions/types';

// for one client
const StripeCustomersReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_STRIPE_CUSTOMERS_REQUEST:
      return [];
    case FETCH_STRIPE_CUSTOMERS_SUCCESS:
      return action.payload;
    case FETCH_STRIPE_CUSTOMERS_FAILURE:
      return [];
    case CREATE_STRIPE_CUSTOMERS_REQUEST:
      return state;
    case CREATE_STRIPE_CUSTOMERS_SUCCESS:
      return state;
    case CREATE_STRIPE_CUSTOMERS_FAILURE:
      return state;
    default:
      return state;
  }
};

export default StripeCustomersReducer;
