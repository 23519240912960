import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Box } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import UserInfo from './UserInfo';
import { ROOT_URL } from '../../environment';
import TimezoneSelect from './TimezoneSelect';

const adminRoutes = [
  {
    url: '/admin',
    title: <FormattedMessage defaultMessage="Dashboard" />,
    icon: '../../../images/dashboard-navigation/dashboardicon.svg',
    key: 'dashboard',
  },
  {
    url: '/admin/practitioner_v2/1',
    title: <FormattedMessage defaultMessage="Practitioner V2 (NEW)" />,
    icon: '../../../images/dashboard-navigation/clientsicon.svg',
    key: 'practitioner_v2',
  },
  {
    url: '/admin/practitioner',
    title: <FormattedMessage defaultMessage="Practitioner" />,
    icon: '../../../images/dashboard-navigation/clientsicon.svg',
    key: 'practitioner',
  },
  {
    url: '/admin/practice',
    title: <FormattedMessage defaultMessage="Practice" />,
    icon: '../../../images/dashboard-navigation/clientsicon.svg',
    key: 'practice',
  },
  {
    url: '/admin/specialized_practitioner',
    title: <FormattedMessage defaultMessage="Specialized Practitioner" />,
    icon: '../../../images/dashboard-navigation/clientsicon.svg',
    key: 'specialized_practitioner',
  },
  {
    url: '/admin/clients',
    title: <FormattedMessage defaultMessage="Clients" />,
    icon: '../../../images/dashboard-navigation/clientsicon.svg',
    key: 'admin_clients',
  },
  {
    url: '/admin/appointments',
    title: <FormattedMessage defaultMessage="Appointments" />,
    icon: '../../../images/dashboard-navigation/scheduleicon.svg',
    key: 'appointments',
  },
  {
    url: '/admin/upload_to_server',
    title: <FormattedMessage defaultMessage="Upload To Server" />,
    icon: '../../../images/dashboard-navigation/scheduleicon.svg',
    key: 'upload_to_server',
  },
  {
    url: '/admin/reports/clinical',
    title: <FormattedMessage defaultMessage="Reports" />,
    icon: '../../../images/dashboard-navigation/reportsicon.svg',
    key: 'reports',
  },
  {
    url: '/admin/discount_codes',
    title: <FormattedMessage defaultMessage="Discount Codes" />,
    icon: '../../../images/dashboard-navigation/processicon.svg',
    key: 'discount_codes',
  },
  {
    url: '/admin/provider_payouts',
    title: <FormattedMessage defaultMessage="Payouts" />,
    icon: '../../../images/dashboard-navigation/processicon.svg',
    key: 'payouts',
  },
  {
    url: '/admin/failed_payments',
    title: <FormattedMessage defaultMessage="Failed Payments" />,
    icon: '../../../images/dashboard-navigation/processicon.svg',
    key: 'failed_payments',
  },
];

const practiceManagementRoutes = [
  {
    title: <FormattedMessage defaultMessage="Dashboard" />,
    icon: '../../../images/dashboard-navigation/dashboardicon.svg',
    url: '/dashboard',
    key: 'dashboard',
  },
  {
    url: '/schedule',
    title: <FormattedMessage defaultMessage="Schedule" />,
    icon: '../../../images/dashboard-navigation/scheduleicon.svg',
    key: 'schedule',
  },
  {
    url: '/clients',
    title: <FormattedMessage defaultMessage="Clients" />,
    icon: '../../../images/dashboard-navigation/clientsicon.svg',
    key: 'clients',
  },
  {
    url: '/session_notes/documents',
    title: <FormattedMessage defaultMessage="Session Notes" />,
    icon: '../../../images/dashboard-navigation/processicon.svg',
    key: 'session_notes',
  },
  {
    url: '/shared_files/files_from_clients',
    title: <FormattedMessage defaultMessage="Shared Files" />,
    icon: '../../../images/dashboard-navigation/processicon.svg',
    key: 'shared_files',
  },
  {
    url: '/billing',
    title: <FormattedMessage defaultMessage="Billing" />,
    icon: '../../../images/dashboard-navigation/billing.svg',
    key: 'billing',
  },
];
const accountSettingsRoutes = [
  {
    url: '/preferences/account_details',
    title: <FormattedMessage defaultMessage="Preferences" />,
    icon: '../../../images/dashboard-navigation/preferencesicon.svg',
    key: 'preferences',
  },
  {
    url: '/payouts',
    title: <FormattedMessage defaultMessage="Payouts" />,
    icon: '../../../images/dashboard-navigation/processicon.svg',
    key: 'payouts',
  },
];

const subscriptionRoutes = [
  {
    url: '/practice_management/subscription',
    title: <FormattedMessage defaultMessage="Inkblot Practice Subscription" />,
    icon: '../../../images/dashboard-navigation/practicemanagementicon.svg',
    key: 'practice_management',
  },
];

const supportRoutes = [
  {
    url: '/zendesk_redirect',
    title: <FormattedMessage defaultMessage="Knowledge Base" />,
    icon: '../../../images/dashboard-navigation/knowledgebase.svg',
    newTab: true,
  },
  {
    url: '/support',
    title: <FormattedMessage defaultMessage="Support" />,
    icon: '../../../images/dashboard-navigation/preferencesicon.svg',
    key: 'support',
  },
];

const drawerWidth = 220;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    paddingTop: '16px',
    width: drawerWidth,
    borderRight: 'none',
    backgroundColor: '#252A67',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  selected: {
    backgroundColor: 'rgba(144,243,168,.1) !important',
    borderLeft: 'white 4px solid !important',
  },
  navigationContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  topNavigation: {
    height: '100vh',
    overflow: 'auto',
  },
  bottomNavigation: {
    height: '220px',
    position: 'relative',
    backgroundColor: '#252A67',
    width: '100%',
    borderTop: 'solid white',
  },
  primaryText: {
    fontFamily: 'Source Sans Pro',
    color: '#BDCAE5',
    fontSize: '14px',
    fontWeight: '600',
  },
  activeText: {
    fontFamily: 'Source Sans Pro',
    color: '#FFFFFF',
    fontSize: '14px',
    fontWeight: '600',
  },
}));

const Heading = styled('h1')({
  color: 'white',
  fontSize: '12px',
  textTransform: 'uppercase',
  fontWeight: '600',
  marginLeft: '30px',
  marginRight: '30px',
});

export default function Navigation({ user }) {
  const classes = useStyles();

  const practiceAccess =
    moment(_.get(user, 'subscription.next_renewal', 0)).isAfter(moment()) ||
    _.get(user, 'provider_detail.grandfathered_yn', false);

  const renderRoutes = routes => (
    <List style={{ paddingTop: 0, paddingBottom: 0 }}>
      {routes.map(route => (
        <Link key={route.url} to={route.url} target={route.newTab && '_blank'}>
          <ListItem
            button
            disableGutters
            selected={window.location.pathname.split('/')[1] === route.key}
            classes={{ selected: classes.selected }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '30px',
                marginRight: '30px',
              }}
            >
              <ListItemIcon
                style={{
                  minWidth: '40px',
                  width: '16px',
                  height: '16px',
                }}
              >
                <img src={route.icon} alt={route.title} />
              </ListItemIcon>
              <ListItemText
                primary={route.title}
                classes={
                  window.location.pathname.split('/')[1] === route.key
                    ? { primary: classes.activeText }
                    : { primary: classes.primaryText }
                }
              />
            </Box>
          </ListItem>
        </Link>
      ))}
    </List>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar
          style={{ justifyContent: 'flex-end', backgroundColor: '#252A67' }}
        >
          <UserInfo user={user} />
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div
          style={{
            backgroundColor: '#252A67',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '18px',
          }}
        >
          <div style={{ width: '80%' }}>
            <Link to={user.is_admin ? '/admin' : '/dashboard'}>
              <img
                style={{ width: '100%' }}
                src={`${ROOT_URL}/images/dashboard-navigation/logomarkpractice.png`}
                alt={<FormattedMessage defaultMessage="Inkblot" />}
              />
            </Link>
          </div>
        </div>
        {window.location.pathname.split('/')[1] === 'admin' ? (
          <Box
            component="main"
            style={{
              overflow: 'hidden',
            }}
          >
            <Box className={classes.navigationContainer}>
              <Box className={classes.topNavigation}>
                <Heading>
                  <FormattedMessage defaultMessage="Admin Management" />
                </Heading>
                {renderRoutes(adminRoutes)}
              </Box>
              <Box className={classes.bottomNavigation}>
                <Box style={{ marginLeft: '12px' }}>
                  <TimezoneSelect user={user} />
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            component="main"
            style={{
              overflow: 'hidden',
            }}
          >
            <Box className={classes.navigationContainer}>
              <Box className={classes.topNavigation}>
                <Heading>
                  <FormattedMessage defaultMessage="Practice Management" />
                </Heading>
                {renderRoutes(practiceManagementRoutes)}
                <Heading style={{ paddingTop: '40px' }}>
                  <FormattedMessage defaultMessage="Account Settings" />
                </Heading>
                {renderRoutes(
                  practiceAccess
                    ? [...accountSettingsRoutes, ...subscriptionRoutes]
                    : accountSettingsRoutes,
                )}
              </Box>
              <Box className={classes.bottomNavigation}>
                <Box style={{ marginLeft: '12px' }}>
                  <TimezoneSelect user={user} />
                </Box>
                <Box
                  style={{
                    paddingBottom: '30px',
                  }}
                >
                  {renderRoutes(supportRoutes)}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Drawer>
    </div>
  );
}

Navigation.propTypes = {
  user: PropTypes.object.isRequired,
};
