import {
  OPEN_GLOBAL_SNACKBAR,
  CLOSE_GLOBAL_SNACKBAR,
} from '../actions/types';

const intialState = {
  open: false,
  message: '',
};

export default (state = intialState, action) => {
  switch (action.type) {
    case OPEN_GLOBAL_SNACKBAR:
      return ({ open: true, message: action.payload });
    case CLOSE_GLOBAL_SNACKBAR:
      return ({ open: false, message: '' });
    default:
      return state;
  }
};
