import _ from 'lodash';
import {
  ADMIN_FETCH_REPORT_SUCCESS,
  ADMIN_FETCH_ANALYTICS,
  ADMIN_FETCH_AVAILABILITY_BY_ID_SUCCESS,
  ADMIN_MISC_SET_PAYOUT_FILTERS,
  ADMIN_MISC_SET_FAILED_PAYMENTS_FILTERS,
  ADMIN_MISC_SET_SESSION_NOTE_FILTERS,
} from '../../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case ADMIN_FETCH_REPORT_SUCCESS:
      return { ...state, report: _.mapKeys(action.payload.data, 'id') };
    case ADMIN_FETCH_ANALYTICS:
      return {
        ...state,
        analytics: { appointment_count: action.payload.data },
      };
    // Should be in reducer_availability.js, but causes double render on calendar
    case ADMIN_FETCH_AVAILABILITY_BY_ID_SUCCESS:
      return { ...state, availability: action.payload.data };
    case ADMIN_MISC_SET_PAYOUT_FILTERS:
      return { ...state, payout_filters: { ...action.payload } };
    case ADMIN_MISC_SET_FAILED_PAYMENTS_FILTERS:
      return { ...state, failed_payment_filters: { ...action.payload } };
    case ADMIN_MISC_SET_SESSION_NOTE_FILTERS:
      return { ...state, session_note_filters: { ...action.payload } };
    default:
      return state;
  }
}
