import _ from 'lodash';
import {
  FETCH_FILE_SHARES,
  CREATE_RESOURCE,
  FETCH_SHARED_WITH,
} from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_FILE_SHARES:
      return _.mapKeys(action.payload.data, 'id');
    case CREATE_RESOURCE:
      return { ...state, [action.payload.data.id]: action.payload.data };
    case FETCH_SHARED_WITH:
      return _.mapKeys(action.payload, 'id');
    default:
      return state;
  }
}
