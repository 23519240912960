import _ from 'lodash';
import {
  FETCH_DOCUMENTS_SUCCESS,
  UPDATE_DOCUMENT,
  FETCH_DOCUMENT,
  CREATE_DIAGNOSIS,
} from '../actions/types';

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_DOCUMENTS_SUCCESS:
      return { ...state, data: _.mapValues(action.payload.data) };
    case FETCH_DOCUMENT:
      return { ...state, document: action.payload.data };
    case UPDATE_DOCUMENT:
      return { ...state, document: action.payload.data };
    case CREATE_DIAGNOSIS:
      return { ...state, diagnosis: action.payload.data };
    default:
      return state;
  }
}
