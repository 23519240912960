import _ from 'lodash';
import { FETCH_APPOINTMENT_TYPES } from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case FETCH_APPOINTMENT_TYPES:
      return _.mapKeys(action.payload.data, 'id');
    default:
      return state;
  }
}
