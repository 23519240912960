import _ from 'lodash';
import {
  UPDATE_APPOINTMENT_SESSIONS,
  FETCH_APPOINTMENT_NOTES,
  UPDATE_ASSESSMENT_SCORE,
  FETCH_APPOINTMENT_SUCCESS,
  FETCH_SESSION_STATS,
} from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_APPOINTMENT_SUCCESS:
      return { ...state, appointment: action.payload.data };
    case UPDATE_APPOINTMENT_SESSIONS:
      return { ...state, sessions: action.payload.data };
    case FETCH_APPOINTMENT_NOTES:
      return { ...state, appointment_notes: action.payload.data };
    case UPDATE_ASSESSMENT_SCORE:
      return { ...state, assessment_scores: action.payload.data };
    case FETCH_SESSION_STATS:
      return {
        ...state,
        pre_scores: action.payload.data.pre_session_overall,
        post_scores: action.payload.data.post_session_overall,
      };
    default:
      return state;
  }
};
