import {
  FETCH_GOVERNING_BODIES,
  UPDATE_GOVERNING_BODIES_SUCCESS,
  DELETE_GOVERNING_BODIES_SUCCESS,
} from '../actions/types';

export default function(state = {}, action) {
  switch (action.type) {
    case FETCH_GOVERNING_BODIES:
      return action.payload.data;
    case UPDATE_GOVERNING_BODIES_SUCCESS:
      return action.payload.data;
    case DELETE_GOVERNING_BODIES_SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
}
