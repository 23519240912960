import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchUser } from '../actions/user';
import { setDefaultTimezone } from '../utils/timezone';

class NewVideoLayout extends Component {
  static propTypes = {
    fetchUser: PropTypes.func.isRequired,
    authenticated: PropTypes.bool,
    children: PropTypes.object.isRequired,
  };

  static defaultProps = {
    authenticated: false,
  };

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.fetchUser(response => setDefaultTimezone(response.data));
    }
  }

  render() {
    const { authenticated } = this.props;
    if (authenticated) {
      return <div>{this.props.children}</div>;
    }
    return <div>{this.props.children}</div>;
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.user,
  };
}

export default withRouter(
  connect(mapStateToProps, { fetchUser })(NewVideoLayout),
);
