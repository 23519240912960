import _ from 'lodash';
import {
  FETCH_STATES,
  FETCH_AGE_GROUPS,
  FETCH_DEGREES,
  FETCH_THERAPEUTIC_ORIENTATION,
  FETCH_DIAGNOSIS_TYPES,
  FETCH_SPECIALITIES,
  FETCH_GENDER_IDENTITIES,
  FETCH_RACES,
  FETCH_DESIGNATIONS,
  FETCH_TRAINING_PROGRAMS,
  FETCH_DISORDERS,
  FETCH_APPROACH_TO_CARES,
  FETCH_LANGUAGES,
  FETCH_RELIGIONS,
  FETCH_PRONOUNS,
  FETCH_INSURANCE_COMPANIES,
  FETCH_GENERIC_ROLES,
  FETCH_TWILIO_TOKEN,
  EMAIL_ERROR,
  FETCH_AVAILABILITY_BY_ID,
  SET_CALENDAR_RANGE,
  FETCH_MESSAGE_TOKEN_SUCCESS,
  FETCH_GALLIVAN_SCHOOLS,
  CHECK_NEW_MESSAGE,
  SET_NEW_MESSAGE_HANDLER,
  URL_ERROR,
  SET_APP_LOCALE,
} from '../actions/types';

const initialState = {
  ageGroups: [],
  degrees: [],
  designations: [],
  disorders: [],
  languages: [],
  religions: [],
  insuranceCompanies: [],
  subscriptions: [],
  states: [],
  approachesToCare: [],
  newMessageHandler: null,
  therapeuticOrientations: [],
  specialities: [],
  diagnosisTypes: [],
  genderIdentities: [],
  races: [],
  appLocale: localStorage.getItem('locale') || 'en',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_AGE_GROUPS:
      return { ...state, ageGroups: action.payload.data };
    case FETCH_STATES:
      return { ...state, states: action.payload.data };
    case FETCH_DEGREES:
      return { ...state, degrees: action.payload.data };
    case FETCH_THERAPEUTIC_ORIENTATION:
      return { ...state, therapeuticOrientations: action.payload.data };
    case FETCH_DIAGNOSIS_TYPES:
      return { ...state, diagnosisTypes: action.payload.data };
    case FETCH_SPECIALITIES:
      return { ...state, specialities: action.payload.data };
    case FETCH_GENDER_IDENTITIES:
      return { ...state, genderIdentities: action.payload.data };
    case FETCH_RACES:
      return { ...state, races: action.payload.data };
    case FETCH_DESIGNATIONS:
      return { ...state, designations: action.payload.data };
    case FETCH_TRAINING_PROGRAMS:
      return { ...state, training_programs: action.payload.data };
    case FETCH_DISORDERS:
      return { ...state, disorders: action.payload.data };
    case FETCH_LANGUAGES:
      return { ...state, languages: action.payload.data };
    case FETCH_APPROACH_TO_CARES:
      return {
        ...state,
        approachesToCare: action.payload.data,
      };
    case FETCH_GENERIC_ROLES:
      return {
        ...state,
        genericRoles: action.payload.data,
      };
    case FETCH_RELIGIONS:
      return { ...state, religions: action.payload.data };
    case FETCH_PRONOUNS:
      return { ...state, pronouns: action.payload.data };
    case FETCH_INSURANCE_COMPANIES:
      return {
        ...state,
        insuranceCompanies: action.payload.data,
      };
    case FETCH_TWILIO_TOKEN:
      return { ...state, token: action.payload.data };
    case EMAIL_ERROR:
      return {
        ...state,
        emailError: action.payload ? action.payload.data.message : null,
      };
    case URL_ERROR:
      return {
        ...state,
        urlError: action.payload ? action.payload.data.message : null,
      };
    case FETCH_AVAILABILITY_BY_ID:
      return { ...state, availability: action.payload.data };
    case SET_CALENDAR_RANGE:
      return { ...state, calendarRange: action.payload };
    case FETCH_MESSAGE_TOKEN_SUCCESS:
      return { ...state, token: action.payload.data };
    case FETCH_GALLIVAN_SCHOOLS:
      return { ...state, gallivanSchools: action.payload.data };
    case CHECK_NEW_MESSAGE:
      return { ...state, newMessage: action.payload.data };
    case SET_NEW_MESSAGE_HANDLER:
      return { ...state, newMessageHandler: action.payload.data };
    case 'FETCH_SUBSCRIPTIONS_SUCCESS':
      return { ...state, subscriptions: _.sortBy(action.payload.data, 'id') };
    case 'FETCH_SUBSCRIPTION_INVOICES_SUCCESS':
      return { ...state, sub_invoices: action.payload.data };
    case SET_APP_LOCALE:
      return { ...state, appLocale: action.payload.data };
    default:
      return state;
  }
}
