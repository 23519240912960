import get from 'lodash/get';
import moment from 'moment-timezone';
import English from '../../lang/en.json';
import French from '../../lang/fr.json';
import { createIntl, createIntlCache } from 'react-intl';

const timezoneKey = 'current-timezone';

const intl = () => {
  const cache = createIntlCache();
  const locale = localStorage.getItem('locale') || 'en';
  const messages = locale == 'en' ? English : French;

  return createIntl({ locale, messages }, cache);
};

const { formatMessage } = intl();

export const timezones = [
  {
    label: formatMessage({ defaultMessage: 'Hawaii-Aleutian Time' }),
    value: 'Pacific/Honolulu',
  },
  {
    label: formatMessage({ defaultMessage: 'Alaska Time' }),
    value: 'America/Juneau',
  },
  {
    label: formatMessage({
      defaultMessage: 'Pacific Time (Los Angeles/Vancouver)',
    }),
    value: 'America/Vancouver',
  },
  {
    label: formatMessage({ defaultMessage: 'Mountain Time (Denver/Edmonton)' }),
    value: 'America/Edmonton',
  },
  {
    label: formatMessage({ defaultMessage: 'Central Time (Chicago/Winnipeg)' }),
    value: 'America/Winnipeg',
  },
  {
    label: formatMessage({ defaultMessage: 'Eastern Time (New York/Toronto)' }),
    value: 'America/Toronto',
  },
  {
    label: formatMessage({ defaultMessage: 'Atlantic Time (Halifax)' }),
    value: 'America/Halifax',
  },
  {
    label: formatMessage({ defaultMessage: 'Newfoundland Time' }),
    value: 'America/St_Johns',
  },
  {
    label: formatMessage({
      defaultMessage: 'Saskatchewan (Central Standard Time)',
    }),
    value: 'America/Swift_Current',
  },
  {
    label: formatMessage({ defaultMessage: 'Yukon (Mountain Standard Time)' }),
    value: 'MST',
  },
  {
    label: formatMessage({
      defaultMessage: 'Pickle Lake/Atikokan (Eastern Standard Time)',
    }),
    value: 'EST',
  },
];

export const intlTimezones = [
  {
    label: '(UTC-12:00) International Date Line West',
    value: 'Pacific/Wallis',
  },
  {
    label: '(UTC-11:00) Coordinated Universal Time-11',
    value: 'Pacific/Pago_Pago',
  },
  {
    label: '(UTC-10:00) Hawaii',
    value: 'US/Hawaii',
  },
  {
    label: '(UTC-09:00) Alaska',
    value: 'US/Alaska',
  },
  {
    label: '(UTC-08:00) Pacific Time (US & Canada)',
    value: 'Canada/Pacific',
  },
  {
    label: '(UTC-07:00) Mountain Time (US & Canada)',
    value: 'US/Mountain',
  },
  {
    label: '(UTC-06:00) Central Time (US & Canada)',
    value: 'US/Central',
  },
  {
    label: '(UTC-05:00) Eastern Time (US & Canada)',
    value: 'US/Eastern',
  },
  {
    label: '(UTC-04:30) Caracas',
    value: 'America/Caracas',
  },
  {
    label: '(UTC-04:00) Atlantic Time (Canada)',
    value: 'Canada/Atlantic',
  },
  {
    label: '(UTC-03:30) Newfoundland',
    value: 'Canada/Newfoundland',
  },
  {
    label: '(UTC-03:00) Greenland',
    value: 'America/Godthab',
  },
  {
    label: '(UTC-02:00) Mid-Atlantic - Old',
    value: 'America/Noronha',
  },
  {
    label: '(UTC-01:00) Azores',
    value: 'Atlantic/Azores',
  },
  {
    label: '(UTC) Coordinated Universal Time',
    value: 'Etc/Greenwich',
  },
  {
    label: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    value: 'Europe/Amsterdam',
  },
  {
    label: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    value: 'Europe/Helsinki',
  },
  {
    label: '(UTC+03:00) Nairobi',
    value: 'Africa/Nairobi',
  },
  {
    label: '(UTC+03:30) Tehran',
    value: 'Asia/Tehran',
  },
  {
    label: '(UTC+04:00) Abu Dhabi, Muscat',
    value: 'Asia/Muscat',
  },
  {
    label: '(UTC+04:30) Kabul',
    value: 'Asia/Kabul',
  },
  {
    label: '(UTC+05:00) Ashgabat, Tashkent',
    value: 'Asia/Tashkent',
  },
  {
    label: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    value: 'Asia/Kolkata',
  },
  {
    label: '(UTC+05:45) Kathmandu',
    value: 'Asia/Katmandu',
  },
  {
    label: '(UTC+06:00) Ekaterinburg',
    value: 'Asia/Yekaterinburg',
  },
  {
    label: '(UTC+06:30) Yangon (Rangoon)',
    value: 'Asia/Rangoon',
  },
  {
    label: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    value: 'Asia/Hong_Kong',
  },
  {
    label: '(UTC+09:00) Osaka, Sapporo, Tokyo',
    value: 'Asia/Tokyo',
  },
  {
    label: '(UTC+09:30) Adelaide',
    value: 'Australia/Adelaide',
  },
  {
    label: '(UTC+10:00) Canberra, Melbourne, Sydney',
    value: 'Australia/Melbourne',
  },
  {
    label: '(UTC+11:00) Vladivostok',
    value: 'Asia/Vladivostok',
  },
  {
    label: '(UTC+12:00) Auckland, Wellington',
    value: 'Pacific/Auckland',
  },
  {
    label: '(UTC+13:00) Samoa',
    value: 'Pacific/Samoa',
  },
];

export const getTimezoneLabel = value => {
  let label = value;

  for (let index = 0; index < timezones.length; index++) {
    if (timezones[index].value === value) {
      label = timezones[index].label;
      break;
    }
  }

  return label;
};

export const setDefaultTimezone = user => {
  const timezone = get(user, 'preference.timezone', moment.tz.guess());
  const currentTimezone = localStorage.getItem(timezoneKey);

  if (timezone !== currentTimezone) {
    localStorage.setItem(timezoneKey, timezone);
  }

  moment.tz.setDefault(timezone);
};

export const useDefaultTimezone = () => {
  const timezone = localStorage.getItem(timezoneKey) || moment.tz.guess();

  moment.tz.setDefault(timezone);
};
