import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { injectIntl } from 'react-intl';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { timezones, getTimezoneLabel } from '../../utils/timezone';
import { updatePreferences } from '../../actions/preferences';

const P = styled('p')({
  marginBottom: 0,
  color: 'white',
  fontSize: '12px',
});

class TimezoneSelect extends Component {
  constructor() {
    super();
    this.state = {
      time: null,
      anchorEl: null,
    };
  }

  componentDidMount() {
    this.interval = setInterval(this.update, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  update = () => {
    const { user } = this.props;
    const timezone = user && user.preference && user.preference.timezone;
    const currentTime = timezone ? moment().tz(timezone) : moment();

    this.setState({
      time: currentTime,
    });
  };

  changeTimezone = timezone => {
    this.props.updatePreferences({ preference: { timezone } }, () => {
      window.location.reload(false);
    });
  };

  render() {
    const { time } = this.state;
    const { user, intl } = this.props;
    const timezone = user && user.preference && user.preference.timezone;

    const formattedTime = intl.formatDate(time, {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: localStorage.getItem('current-timezone'),
    });

    return (
      <Box style={{ paddingTop: '20px' }}>
        <Box
          onClick={event =>
            this.setState({
              anchorEl: event.currentTarget,
            })
          }
          style={{ cursor: 'pointer' }}
        >
          <P style={{ fontWeight: 600 }}>
            {timezone && getTimezoneLabel(timezone)}
          </P>
          <Box
            style={{ display: 'flex', alignItems: 'center', height: '20px' }}
          >
            <P>
              {intl.formatMessage(
                {
                  defaultMessage: 'Time Now: {time}',
                },
                { time: formattedTime },
              )}
            </P>
            <ExpandMoreIcon style={{ color: 'white' }} />
          </Box>
        </Box>
        <Menu
          open={this.state.anchorEl || false}
          onClose={() => this.setState({ anchorEl: null })}
          disableTypography
          anchorEl={this.state.anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {timezones.map(zone => (
            <MenuItem
              onClick={() => this.changeTimezone(zone.value)}
              primaryText={zone.label}
              key={zone.value}
              style={{ fontFamily: 'Source Sans Pro' }}
            >
              {zone.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  }
}

TimezoneSelect.propTypes = {
  user: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  updatePreferences: PropTypes.func.isRequired,
};

export default injectIntl(
  connect(null, {
    updatePreferences,
  })(TimezoneSelect),
);
