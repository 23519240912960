import { FETCH_ANALYTICS } from '../actions/types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_ANALYTICS:
      return {
        ...state,
        appointment_count: action.payload.data,
      };
    default:
      return state;
  }
};
