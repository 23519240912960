/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setAppLocale } from 'actions/misc';
import { THERAPY_URL } from '../environment';
import { fetchUser } from '../actions/user';
import { signoutUser } from '../actions/auths';
import Navigation from './Navigation';
import GlobalSnackbarMessage from '../components/global/SnackbarMessage';
import isClient from '../utils/isClient';
import VerificationDialog from './VerificationDialog';
import { setDefaultTimezone } from '../utils/timezone';

const closeVerificationDialogKey = 'has-closed-verification-dialog';

class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
    };
  }

  componentDidMount() {
    if (this.props.authenticated) {
      this.onAuthenticated();
    }
  }

  componentDidUpdate(nextProps) {
    const { user } = this.props;

    if (user !== nextProps.user) {
      this.toggleDialog();
    }
  }

  setComm100 = user => {
    localStorage.setItem('user_id', _.get(user, 'id'));
    localStorage.setItem('full_name', _.get(user, 'full_name'));
    localStorage.setItem(
      'verified_yn',
      _.get(user, 'provider_detail.verified_yn'),
    );
    localStorage.setItem(
      'province',
      _.get(user, 'practice_address.state.name'),
    );
    localStorage.setItem(
      'counselling_type',
      _.get(user, 'generic_role.role_name', 'Regular Counselling'),
    );
  };

  onAuthenticated = () => {
    this.props.fetchUser(response => {
      const user = response.data;

      Sentry.configureScope(scope => {
        scope.setUser({ id: user.id, email: user.email });
      });
      this.props.setAppLocale(user.locale);
      moment.locale(user.locale);
      setDefaultTimezone(user);
      this.setComm100(user);

      if (isClient(user.roles || [])) {
        window.location.href = `${THERAPY_URL}/`;
      }
    });
  };

  toggleDialog = () => this.setState({ dialogOpen: this.showDialog() });

  // Determine whether to show the verification dialog or not
  showDialog = () => {
    const { user } = this.props;
    const practitionerAccess = get(user, 'access.practitioner_yn', false);
    const verified = get(user, 'provider_detail.verified_yn', false);
    const submittedDocuments = get(user, 'counsellor_documents', []).length > 0;

    return (
      !localStorage.getItem(closeVerificationDialogKey) &&
      practitionerAccess &&
      !verified &&
      !submittedDocuments
    );
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
    localStorage.setItem(closeVerificationDialogKey, true);
  };

  render() {
    const {
      authenticated,
      user,
      location: { pathname },
      history,
    } = this.props;
    const { dialogOpen } = this.state;

    if (
      authenticated &&
      !pathname.includes('signup') &&
      !pathname.includes('signup_specialized') &&
      !pathname.includes('zendesk_redirect')
    ) {
      return (
        <Box sx={{ display: 'flex' }}>
          <Navigation user={user} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              paddingTop: '100px',
            }}
          >
            {this.props.children}
          </Box>
          <GlobalSnackbarMessage />
          <VerificationDialog
            open={dialogOpen}
            handleClose={this.handleClose}
            onClick={() => history.push('/signup?verify=true')}
          />
        </Box>
      );
    }
    return <Box component="main">{this.props.children}</Box>;
  }
}
AppLayout.propTypes = {
  fetchUser: PropTypes.func.isRequired,
  authenticated: PropTypes.bool,
  setAppLocale: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

AppLayout.defaultProps = {
  authenticated: false,
};

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.user,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchUser,
    signoutUser,
    setAppLocale,
  })(AppLayout),
);
