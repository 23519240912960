import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import { injectIntl } from 'react-intl';
import GlobalDialog from '../components/global/Dialog';
import GlobalTitle from '../components/global/Title';

function VerificationDialog({ open, handleClose, onClick, intl }) {
  const actions = [
    <FlatButton
      className="flat-button-green"
      label={intl.formatMessage({ defaultMessage: 'Verify' })}
      onClick={onClick}
    />,
    <FlatButton
      className="return-button"
      label={intl.formatMessage({ defaultMessage: 'Cancel' })}
      onClick={handleClose}
    />,
  ];

  return (
    <GlobalDialog
      modal={false}
      open={open}
      handleClose={() => {}}
      autoDetectWindowHeight={false}
      contentClassName="error-dialog"
      actions={actions}
    >
      <GlobalTitle
        title={intl.formatMessage({ defaultMessage: 'Verification Required' })}
      />
      <div className="center-items">
        <p>
          {intl.formatMessage({
            defaultMessage:
              'You have not submitted the necessary documents yet to be verified. Please click the button below to be redirected to the verification page',
          })}
        </p>
      </div>
    </GlobalDialog>
  );
}

VerificationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(VerificationDialog);
