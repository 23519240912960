import _ from 'lodash';

import {
  ADMIN_UPDATE_DESIGNATIONS,
  ADMIN_UPDATE_DISORDERS,
  ADMIN_UPDATE_LANGUAGES,
  ADMIN_UPDATE_RELIGIONS,
  ADMIN_UPDATE_DEGREES,
  ADMIN_UPDATE_AGE_GROUPS,
  ADMIN_UPDATE_PROVIDER_DETAILS,
  ADMIN_UPDATE_PROVIDER_BIO,
  ADMIN_UPDATE_ADDRESS,
  ADMIN_UPDATE_PHONE_NUMBERS,
  ADMIN_UPDATE_PREFERENCES,
  ADMIN_UPDATE_REFERRAL_CODE,
  ADMIN_FETCH_COUNSELLOR_DOCUMENTS,
  ADMIN_VERIFY_COUNSELLOR_DOCUMENT,
} from '../../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case ADMIN_UPDATE_AGE_GROUPS:
      return action.payload.data;
    case ADMIN_UPDATE_DEGREES:
      return action.payload.data;
    case ADMIN_UPDATE_DESIGNATIONS:
      return action.payload.data;
    case ADMIN_UPDATE_DISORDERS:
      return action.payload.data;
    case ADMIN_UPDATE_LANGUAGES:
      return action.payload.data;
    case ADMIN_UPDATE_RELIGIONS:
      return action.payload.data;
    case ADMIN_UPDATE_PROVIDER_DETAILS:
      return action.payload.data;
    case ADMIN_UPDATE_PROVIDER_BIO:
      return action.payload.data;
    case ADMIN_UPDATE_ADDRESS:
      return action.payload.data;
    case ADMIN_UPDATE_PHONE_NUMBERS:
      return action.payload.data;
    case ADMIN_UPDATE_PREFERENCES:
      return action.payload.data;
    case ADMIN_UPDATE_REFERRAL_CODE:
      return action.payload.data;
    case ADMIN_FETCH_COUNSELLOR_DOCUMENTS:
      return {
        ...state,
        documents: _.mapKeys(action.payload.data, 'id'),
      };
    case ADMIN_VERIFY_COUNSELLOR_DOCUMENT:
      return {
        ...state,
        documents: {
          ...state.documents,
          [action.payload.data.id]: action.payload.data,
        },
      };
    default:
      return state;
  }
}
