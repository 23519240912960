import _ from 'lodash';
import {
  ADMIN_FETCH_AVAILABILITY_SUCCESS,
  ADMIN_CREATE_AVAILABILITY_SUCCESS,
  ADMIN_UPDATE_AVAILABILITY_SUCCESS,
  ADMIN_DELETE_AVAILABILITY_SUCCESS,
  ADMIN_COMBINE_AVAILABILITY_SUCCESS,
  ADMIN_UPDATE_APPOINTMENT_SUCCESS,
  ADMIN_CANCEL_APPOINTMENT_SUCCESS,
} from '../../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case ADMIN_FETCH_AVAILABILITY_SUCCESS:
      return _.mapKeys(action.payload.data, 'id');
    case ADMIN_CREATE_AVAILABILITY_SUCCESS:
      return { ...state, [action.payload.data.id]: action.payload.data };
    case ADMIN_UPDATE_AVAILABILITY_SUCCESS:
      return { ...state, [action.payload.data.id]: action.payload.data };
    case ADMIN_DELETE_AVAILABILITY_SUCCESS:
      return _.omit(state, [`${action.payload.data.id}`]);
    case ADMIN_COMBINE_AVAILABILITY_SUCCESS:
      return state;
    case ADMIN_UPDATE_APPOINTMENT_SUCCESS:
      return { ...state, [action.payload.data.id]: action.payload.data };
    case ADMIN_CANCEL_APPOINTMENT_SUCCESS:
      return state;
    default:
      return state;
  }
}
