import {
  ADMIN_FETCH_APPOINTMENT_PAGINATION_INFO,
  ADMIN_FETCH_CLIENT_PAGINATION_INFO,
  ADMIN_FETCH_PROVIDER_PAGINATION_INFO,
  ADMIN_FETCH_DISCOUNT_CODE_PAGINATION_INFO,
  ADMIN_FETCH_REPORT_PAGINATION_INFO,
  ADMIN_FETCH_PROVIDER_CLIENT_PAGINATION_INFO,
} from '../../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case ADMIN_FETCH_APPOINTMENT_PAGINATION_INFO:
      return { ...state, appointments: action.payload.data };
    case ADMIN_FETCH_DISCOUNT_CODE_PAGINATION_INFO:
      return { ...state, discount_codes: action.payload.data };
    case ADMIN_FETCH_CLIENT_PAGINATION_INFO:
      return { ...state, clients: action.payload.data };
    case ADMIN_FETCH_PROVIDER_PAGINATION_INFO:
      return { ...state, counsellors: action.payload.data };
    case ADMIN_FETCH_REPORT_PAGINATION_INFO:
      return { ...state, reports: action.payload.data };
    case ADMIN_FETCH_PROVIDER_CLIENT_PAGINATION_INFO:
      return { ...state, provider_clients: action.payload.data };

    default:
      return state;
  }
}
