import _ from 'lodash';
import {
  ADMIN_FETCH_PROVIDER_APPOINTMENTS_SUCCESS,
  ADMIN_FETCH_APPOINTMENTS_SUCCESS,
  ADMIN_FETCH_APPOINTMENT_SUCCESS,
  ADMIN_FETCH_CURRENT_APPOINTMENTS_SUCCESS,
  ADMIN_FETCH_CLIENT_APPOINTMENTS_SUCCESS,
} from '../../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case ADMIN_FETCH_APPOINTMENT_SUCCESS:
      return { ...state, appointment: action.payload.data };
    case ADMIN_FETCH_APPOINTMENTS_SUCCESS:
      return { ...state, all: _.mapValues(action.payload.data) };
    case ADMIN_FETCH_PROVIDER_APPOINTMENTS_SUCCESS:
      return { ...state, provider: _.mapValues(action.payload.data) };
    case ADMIN_FETCH_CURRENT_APPOINTMENTS_SUCCESS:
      return { ...state, current: _.mapValues(action.payload.data) };
    case ADMIN_FETCH_CLIENT_APPOINTMENTS_SUCCESS:
      return { ...state, client: _.mapValues(action.payload.data) };
    default:
      return state;
  }
}
