import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchUser } from '../actions/user';
import GlobalToolbar from '../components/global/Toolbar';
import { setDefaultTimezone } from '../utils/timezone';

class SessionLayout extends Component {
  static propTypes = {
    fetchUser: PropTypes.func.isRequired,
    authenticated: PropTypes.bool,
    children: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  static defaultProps = {
    authenticated: false,
  };

  componentDidMount() {
    if (this.props.authenticated) {
      this.props.fetchUser(response => setDefaultTimezone(response.data));
    }
  }

  render() {
    const { authenticated, user } = this.props;
    if (authenticated) {
      return (
        <div>
          <GlobalToolbar user={user} {...this.props} />
          <div
            style={{ backgroundColor: '#f8fdfd', margin: 0, height: '100%' }}
            className="row w-100 session-content-base"
          >
            <div style={{ minHeight: '1024px' }} className="col">
              {this.props.children}
            </div>
          </div>
        </div>
      );
    }
    return <div>{this.props.children}</div>;
  }
}

function mapStateToProps(state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.user,
  };
}

export default withRouter(
  connect(mapStateToProps, { fetchUser })(SessionLayout),
);
