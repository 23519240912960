import {
  FETCH_SH_INIT,
  FETCH_SH_CLIENT_REQUEST,
  FETCH_SH_CLIENT_SUCCESS,
  FETCH_SH_CLIENT_FAILURE,
  FETCH_SH_STRIPE_CUSTOMERS_REQUEST,
  FETCH_SH_STRIPE_CUSTOMERS_SUCCESS,
  FETCH_SH_STRIPE_CUSTOMERS_FAILURE,
} from '../actions/types';

const initialState = {
  client: null,
  stripe_customers: [],
};

// for one client
const ScheduleHelperReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SH_INIT:
      return initialState;
    case FETCH_SH_CLIENT_REQUEST:
      return { ...state, client: initialState.client };
    case FETCH_SH_CLIENT_SUCCESS:
      return { ...state, client: action.payload };
    case FETCH_SH_CLIENT_FAILURE:
      return { ...state, client: initialState.client };
    case FETCH_SH_STRIPE_CUSTOMERS_REQUEST:
      return { ...state, stripe_customers: initialState.stripe_customers };
    case FETCH_SH_STRIPE_CUSTOMERS_SUCCESS:
      return { ...state, stripe_customers: action.payload };
    case FETCH_SH_STRIPE_CUSTOMERS_FAILURE:
      return { ...state, stripe_customers: initialState.stripe_customers };
    default:
      return state;
  }
};

export default ScheduleHelperReducer;
